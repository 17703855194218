import React from "react";
import { H2, P1 } from "../components/styled";
import Button from "../components/Button";
import AdminPage from "../components/AdminPage";
import openURL from "../utility/openURL";
import DocumentTitle from "react-document-title";

export default (props: any) => {
  return (
    <AdminPage>
      <DocumentTitle title="Download - Boxy Suite" />
      <H2>Download Boxy Suite</H2>
      <P1>
        Click on the button below to download the latest version of the Suite.
      </P1>
      <br />
      <Button
        primary
        label="Download"
        onClick={() => {
          openURL("https://accounts.boxysuite.com/redirect/download_suite");
        }}
      />
    </AdminPage>
  );
};
