import React, { useState, useContext } from "react";
import LayoutWrapper from "../components/LayoutWrapper";
import Sidebar from "../components/Sidebar";
import { H2, P2 } from "../components/styled";
import { AuthContext } from "../context/AuthContext";
import { Formik, ErrorMessage } from "formik";
import { Form, Message } from "semantic-ui-react";
import handleNetworkError from "../utility/handleNetworkError";
import Button from "../components/Button";
import api from "../dependencies/api";
import CheckboxWithLabel from "../components/CheckboxWithLabel";
import { Redirect } from "react-router";
import validateRequiredKeys from "../utility/validateRequiredKeys";
import DocumentTitle from "react-document-title";

const validateForm = values => {
  const errors = validateRequiredKeys(values, [
    "code",
    "email",
    "name",
    "password",
    "confirm_password",
    "terms",
    "privacy"
  ]);

  if (values.password !== values.confirm_password) {
    errors.confirm_password = "Must be the same as Password";
  }

  if (values.password && values.password.length < 6) {
    errors.password = "Password must be at least 6 characters long";
  }

  return errors;
};

const createOnSubmit = (forceLogin, setRegistrationCompleted) => {
  return async (values, { setSubmitting }) => {
    const { code, email, name, password, newsletter } = values;

    setSubmitting(true);

    try {
      const res = await api.completeAppSumoSignup(
        code,
        email,
        name,
        password,
        newsletter
      );
      setSubmitting(false);
      forceLogin(res.token, res.user);
      setRegistrationCompleted(true);
    } catch (error) {
      setSubmitting(false);
      handleNetworkError(error);
    }
  };
};

export default props => {
  const { forceLogin } = useContext(AuthContext);
  const [registrationCompleted, setRegistrationCompleted] = useState(false);

  if (registrationCompleted) {
    return <Redirect to={"/"} />;
  }

  return (
    <>
      <DocumentTitle title="Create Account - Boxy Suite" />
      <Sidebar />
      <LayoutWrapper centered medium>
        <H2>Create Your Boxy Suite Account</H2>
        <P2>Welcome! Redeem your code to get started with Boxy Suite.</P2>
        <br />
        <Formik
          initialValues={{
            code: "",
            email: "",
            name: "",
            password: "",
            confirm_password: "",
            terms: false,
            privacy: false,
            newsletter: false
          }}
          validate={validateForm}
          onSubmit={createOnSubmit(forceLogin, setRegistrationCompleted)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Form error onSubmit={handleSubmit} loading={isSubmitting}>
              <Form.Input
                type="text"
                name="code"
                label="AppSumo Code"
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.code}
                error={errors.code && touched.code}
              />
              <Form.Input
                type="email"
                name="email"
                label="Your Email"
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={errors.email && touched.email}
              />
              <Form.Input
                type="name"
                name="name"
                label="Your Name"
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={errors.name && touched.name}
              />
              <Form.Input
                type="password"
                name="password"
                label="Password"
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={errors.password && touched.password}
              />
              <ErrorMessage
                name="password"
                render={msg =>
                  msg !== "required" && <Message error content={msg} />
                }
              />
              <Form.Input
                type="password"
                name="confirm_password"
                label="Confirm Password"
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirm_password}
                error={errors.confirm_password && touched.confirm_password}
              />
              <ErrorMessage
                name="confirm_password"
                render={msg =>
                  msg !== "required" && <Message error content={msg} />
                }
              />
              <CheckboxWithLabel
                name="terms"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.terms}
                label="I agree with the Terms of Service"
                error={errors.terms && touched.terms}
              >
                <P2>
                  Read the{" "}
                  <a
                    href="https://www.boxysuite.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                  .
                </P2>
              </CheckboxWithLabel>

              <CheckboxWithLabel
                name="privacy"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.privacy}
                label="I agree with the Privacy Policy"
                error={errors.privacy && touched.privacy}
              >
                <P2>
                  Your Boxy Suite account email and password are securely stored
                  on our services.
                </P2>
                <P2>
                  We don't see or store the credentials that you use to log into
                  your Google accounts within Boxy Suite apps.
                </P2>
                <P2>
                  Before continuing, read the full{" "}
                  <a
                    href="https://www.boxysuite.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </P2>
              </CheckboxWithLabel>

              <CheckboxWithLabel
                name="newsletter"
                label="Send me very occasional updates about new products via email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newsletter}
                error={errors.newsletter && touched.newsletter}
              />
              <br />
              <Button
                primary
                type="submit"
                disabled={isSubmitting}
                label="Create Account"
              />
            </Form>
          )}
        </Formik>
      </LayoutWrapper>
    </>
  );
};
