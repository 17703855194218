import styled from "styled-components";
import { ReactNode } from "react";

const H2 = styled.h2`
  font-family: Archivo;
  font-weight: 600;
  font-size: 1.7em;
  display: block;
  padding-bottom: 0.7em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  margin-bottom: 0.7em;
  margin-top: 1.5em;
  :first-child {
    margin-top: 1.5em;
  }
`;

const H3 = styled.h3`
  font-family: Archivo;
  font-weight: 600;
  font-size: 1.4em;
  display: block;
  margin-bottom: 0.7em;
  :not(:first-of-type) {
    margin-top: 2em;
  }
`;

const P1 = styled.p`
  font-size: 1.1em;
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 0.5em;
`;

const P2 = styled.p`
  font-size: 1em;
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0.5em;
`;

interface AccountsGridProps {
  columns?: number;
  children?: ReactNode;
}

const AccountsGrid = styled("div")<AccountsGridProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  ${props =>
    props.columns === 3 &&
    `
    grid-template-columns: 1fr 1fr 1fr;
  `}
  grid-gap: 1.5em;
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const SmallLabel = styled.span`
  display: block;
  font-size: 0.9em;
  opacity: 0.4;
`;

const Field = styled.span`
  display: block;
  font-size: 1.1em;
  font-weight: 600;
`;

export { H2, H3, AccountsGrid, SmallLabel, Field, P1, P2 };
