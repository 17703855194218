import React from "react";
import styled from "styled-components";
import img from "../utility/img";

const Container = styled.div`
  position: sticky;
  float: left;
  top: 0;
  width: 20rem;
  background: hsl(224, 30%, 95%);
  padding: 1.7em;
  font-size: 1.1em;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: 14em;
`;

export default props => {
  return (
    <Container>
      <Logo src={img.logos.appsumo} alt="Boxy Suite and AppSumo" />
    </Container>
  );
};
