import React from "react";
import styled from "styled-components";
import { Loader, Dimmer } from "semantic-ui-react";

const LayoutWrapper = styled.div`
  background: #fafbfe;
  display: grid;
  min-height: 100vh;
  position: relative;
  justify-items: center;
  ${props =>
    props.centered &&
    `
    align-items: center;
    `}
  > div:first-child {
    width: 100%;
    padding: 2em 2em 3em;
    max-width: 64em;
    ${props => props.small && `max-width: 36em`}
    ${props => props.medium && `max-width: 46em`}
    >h2:first-of-type {
      margin-top: 0;
    }
  }
`;

export default props => {
  const { loading } = props;

  return (
    <LayoutWrapper {...props}>
      <div>{props.children}</div>
      <Dimmer active={loading} inverted>
        <Loader size="small" />
      </Dimmer>
    </LayoutWrapper>
  );
};
