import React, { useState, useEffect } from "react";
import Subscription from "../../models/Subscription";
import CancelFlowStart from "./CancelFlowStart";
import CancelFlowExtendTrial from "./CancelFlowExtendTrial";
import CancelFlowOffer from "./CancelFlowOffer";
import CancelFlowLastStep from "./CancelFlowLastStep";
import { Loader, Dimmer } from "semantic-ui-react";
import CancelFlowExtendTrialAccepted from "./CancelFlowExtendTrialAccepted";
import useKeyPress from "../../hooks/useKeypress";
import CancelFlowOfferAccepted from "./CancelFlowOfferAccepted";

enum CancelFlowStep {
  Start = 0,
  ExtendTrial = 1,
  Offer = 2,
  LastStep = 3,
  ExtendTrialAccepted = 4,
  OfferAccepted = 5
}

interface Props {
  redeemedOffers: string[];
  subscription: Subscription;
  onCancel?: () => void;
}

const BOXY_SUITE_OLD_MONTHLY_PRODUCT_ID = 539475;
const BOXY_SUITE_OLD_YEARLY_PRODUCT_ID = 540524;
const BOXY_SUITE_YEARLY_PRODUCT_ID = 589201;
const BOXY_SUITE_MONTHLY_PRODUCT_ID = 589200;
const BOXY_SUITE_FOR_TEAMS_PRODUCT_ID = 548293;

const flowEnabledProductIds = [
  BOXY_SUITE_OLD_MONTHLY_PRODUCT_ID,
  BOXY_SUITE_OLD_YEARLY_PRODUCT_ID
];

function handleNextStep(
  redeemedOffers: string[],
  previousStep: CancelFlowStep,
  subscription: Subscription,
  acceptedExtendTrial: boolean,
  acceptedOffer: boolean,
  cancelReason: string,
  setStep: (step: CancelFlowStep) => void
) {
  if (previousStep === CancelFlowStep.Start) {
    // we are at the start of the flow, let's select the next step based on cancel reason and subscription status
    if (
      cancelReason === "not_convinced" &&
      subscription.status === "trialing" &&
      redeemedOffers.indexOf("extend_trial") === -1
    ) {
      setStep(CancelFlowStep.ExtendTrial);
    }
    // else if (
    //   cancelReason === "expensive" &&
    //   redeemedOffers.indexOf("halve_sub_price") === -1
    // ) {
    //   setStep(CancelFlowStep.Offer);
    // }
    else {
      setStep(CancelFlowStep.LastStep);
    }
  } else if (
    previousStep === CancelFlowStep.ExtendTrial &&
    acceptedExtendTrial
  ) {
    setStep(CancelFlowStep.ExtendTrialAccepted);
  } else if (previousStep === CancelFlowStep.Offer && acceptedOffer) {
    setStep(CancelFlowStep.OfferAccepted);
  } else {
    setStep(CancelFlowStep.LastStep);
  }
}

const CancelFlow: React.FC<Props> = props => {
  const { redeemedOffers, subscription, onCancel } = props;
  const [currentStep, setCurrentStep] = useState(CancelFlowStep.Start);
  const [cancelReason, setCancelReason] = useState("");
  const [loading, setLoading] = useState(false);

  const escapePressed = useKeyPress("Escape");

  useEffect(
    () => {
      escapePressed && onCancel && onCancel();
    },
    [escapePressed]
  );

  const content = () => {
    if (
      flowEnabledProductIds.indexOf(subscription.subscription_plan_id) !== -1
    ) {
      const onNext = (prevStep: CancelFlowStep, options?: any) => {
        handleNextStep(
          redeemedOffers,
          prevStep,
          subscription,
          options && options.acceptedExtendTrial,
          options && options.acceptedOffer,
          cancelReason,
          setCurrentStep
        );
      };

      switch (currentStep) {
        case CancelFlowStep.Start:
          return (
            <CancelFlowStart
              onCancel={onCancel}
              onNext={() => onNext(CancelFlowStep.Start)}
              onReasonChange={(reason: string) => setCancelReason(reason)}
            />
          );
        case CancelFlowStep.ExtendTrial:
          return (
            <CancelFlowExtendTrial
              subscriptionId={subscription.subscription_id}
              onCancel={onCancel}
              setLoading={setLoading}
              onNext={(accepted: boolean) => {
                onNext(
                  CancelFlowStep.ExtendTrial,
                  accepted ? { acceptedExtendTrial: true } : undefined
                );
              }}
            />
          );
        case CancelFlowStep.Offer:
          return (
            <CancelFlowOffer
              onCancel={onCancel}
              subscriptionId={subscription.subscription_id}
              setLoading={setLoading}
              onNext={(accepted: boolean) => {
                onNext(
                  CancelFlowStep.Offer,
                  accepted ? { acceptedOffer: true } : undefined
                );
              }}
            />
          );
        case CancelFlowStep.LastStep:
          return (
            <CancelFlowLastStep
              subscriptionId={subscription.subscription_id}
              reasonId={cancelReason}
              onCancel={onCancel}
              setLoading={setLoading}
            />
          );
        case CancelFlowStep.ExtendTrialAccepted:
          return <CancelFlowExtendTrialAccepted onDismiss={onCancel} />;
        case CancelFlowStep.OfferAccepted:
          return <CancelFlowOfferAccepted onDismiss={onCancel} />;
      }
    } else {
      return (
        <CancelFlowLastStep
          subscriptionId={subscription.subscription_id}
          reasonId=""
          onCancel={onCancel}
          setLoading={setLoading}
        />
      );
    }
  };

  return (
    <>
      <Dimmer
        active={loading}
        inverted
        style={{ borderRadius: ".28571429rem" }}
      >
        <Loader size="small" />
      </Dimmer>
      {content()}
    </>
  );
};

export default CancelFlow;
