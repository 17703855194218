import React from "react";
import styled from "styled-components";
import { Form } from "semantic-ui-react";

const LabelWrapper = styled.div`
  > div {
    display: flex;
    align-items: center;
    margin-top: 2em;
    margin-bottom: 1em;
    cursor: pointer;
    > div {
      padding-right: 0.6em;
      margin: 0 !important;
    }
    > label {
      cursor: pointer;
      font-weight: 600;
    }
  }
  label.error {
    color: #9f3a38;
  }
`;

export default props => {
  const { label, name, value, onChange, onBlur, error } = props;

  return (
    <LabelWrapper>
      <div>
        <Form.Input
          type="checkbox"
          name={name}
          id={name}
          onChange={onChange}
          onBlur={onBlur}
          checked={value}
        />
        <label htmlFor={name} name={name} className={error ? "error" : ""}>
          {label}
        </label>
      </div>
      {props.children}
    </LabelWrapper>
  );
};
