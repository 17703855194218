import React, { useState, useLayoutEffect, useEffect } from "react";
import ModalWrapper from "../ModalWrapper";
import { H3, P1 } from "../styled";
import Button from "../Button";
import { Input, Loader, Dimmer } from "semantic-ui-react";

interface Props {
  show: boolean;
  loading?: boolean;
  onCancel?: () => void;
  onConfirm?: (oldPassword: string, newPassword: string) => void;
}

const ChangePasswordModal: React.FC<Props> = props => {
  const { show, loading, onConfirm, onCancel } = props;

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");

  useEffect(
    () => {
      setOldPassword("");
      setNewPassword("");
      setRepeatNewPassword("");
    },
    [show]
  );

  const handleConfirm = () => {
    if (
      oldPassword.length === 0 ||
      newPassword.length === 0 ||
      repeatNewPassword.length === 0
    ) {
      alert("Please insert the missing password");
      return;
    }

    if (newPassword !== repeatNewPassword) {
      alert("Password and confirm password are not the same.");
      return;
    }

    onConfirm && onConfirm(oldPassword, newPassword);
  };

  return (
    <ModalWrapper show={show} loading={loading} big>
      <H3>Change Password</H3>

      <Input
        style={{ width: "100%", marginTop: "0.5em", marginBottom: "1.5em" }}
        type="password"
        value={oldPassword}
        onChange={e => setOldPassword(e.target.value)}
        placeholder="Old Password"
        autoComplete="current-password"
        onKeyPress={(e: any) => {
          if (e.key === "Enter") {
            handleConfirm();
          }
        }}
      />
      <Input
        style={{ width: "100%", marginBottom: "1.5em" }}
        type="password"
        value={newPassword}
        onChange={e => setNewPassword(e.target.value)}
        placeholder="New Password"
        autoComplete="new-password"
        onKeyPress={(e: any) => {
          if (e.key === "Enter") {
            handleConfirm();
          }
        }}
      />
      <Input
        style={{ width: "100%", marginBottom: "1.5em" }}
        type="password"
        value={repeatNewPassword}
        onChange={e => setRepeatNewPassword(e.target.value)}
        placeholder="Repeat Password"
        autoComplete="new-password"
        onKeyPress={(e: any) => {
          if (e.key === "Enter") {
            handleConfirm();
          }
        }}
      />

      <br />

      <Button primary right label="Change Password" onClick={handleConfirm} />
      <Button right label="Cancel" onClick={onCancel} />
    </ModalWrapper>
  );
};

export default ChangePasswordModal;
