import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Account from "./pages/Account";
import Team from "./pages/Team";
import Download from "./pages/Download";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LoggedInRoute from "./components/LoggedInRoute";
import LoggedOutRoute from "./components/LoggedOutRoute";
import Signup from "./pages/Signup";
import AppSumo from "./pages/AppSumo";
import NotFoundPage from "./pages/NotFound";
import ResetPassword from "./pages/ResetPassword";
import CancelFlow from "./pages/CancelFlow/CancelFlow";

export default () => (
  <Router>
    <Switch>
      <LoggedOutRoute exact path="/login" component={Login} />
      <LoggedInRoute exact path="/" component={Account} />
      <LoggedInRoute path="/team" component={Team} />
      <LoggedInRoute path="/download" component={Download} />
      <LoggedInRoute path="/cancel" component={CancelFlow} />
      {/* <LoggedInRoute path="/invite" component={Invite} /> */}

      <Route path="/appsumo" component={AppSumo} />
      <Route path="/signup/:secret" component={Signup} />
      <Route path="/forgot" component={ForgotPassword} />
      <Route path="/reset_password/:secret" component={ResetPassword} />

      {/* THIS NEEDS TO BE THE LAST to act as 404 */}
      <Route component={NotFoundPage} />
    </Switch>
  </Router>
);
