import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Formik, ErrorMessage } from "formik";
import { Form, Popup, Message } from "semantic-ui-react";
import Button from "../components/Button";
import LayoutWrapper from "../components/LayoutWrapper";
import img from "../utility/img";
import handleNetworkError from "../utility/handleNetworkError";
import { AuthContext } from "../context/AuthContext";
import { H2, P1, P2 } from "../components/styled";
import api from "../dependencies/api";
import CheckboxWithLabel from "../components/CheckboxWithLabel";
import validateRequiredKeys from "../utility/validateRequiredKeys";
import DocumentTitle from "react-document-title";

const validateForm = values => {
  const errors = validateRequiredKeys(values, [
    "password",
    "confirm_password",
    "name",
    "terms",
    "privacy"
  ]);

  if (values.password !== values.confirm_password) {
    errors.confirm_password = "Must be the same as Password";
  }

  if (values.password && values.password.length < 6) {
    errors.password = "Password must be at least 6 characters long";
  }

  return errors;
};

const Logo = styled.img`
  display: block;
  margin: 0 auto 3em;
  width: 7em;
`;

const onSubmit = (secret, forceLogin, setRegistrationCompleted) => {
  return async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      await api.completeSignup(
        secret,
        values.name,
        values.password,
        values.newsletter
      );
      setSubmitting(false);
      setRegistrationCompleted(true);
    } catch (error) {
      setSubmitting(false);
      handleNetworkError(error);
    }
  };
};

export default props => {
  const { forceLogin } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [registrationCompleted, setRegistrationCompleted] = useState(false);
  const [email, setEmail] = useState("");

  const { secret } = props.match.params;

  useEffect(
    // fetch the sign up info
    () => {
      setLoading(true);
      api
        .getSignupInfo(secret)
        .then(signupInfo => {
          setEmail(signupInfo.email);

          if (signupInfo.is_registration_completed) {
            setAlreadyRegistered(true);
          }

          setLoading(false);
        })
        .catch(error => {
          handleNetworkError(error);
          setLoading(false);
        });
    },
    [secret]
  );

  useEffect(
    () => {
      if (alreadyRegistered) {
        alert(
          `You already completed registration for this account (${email}). Go ahead and download the apps.`
        );
      }
    },
    [alreadyRegistered]
  );

  if (alreadyRegistered || registrationCompleted) {
    window.location.href = "https://boxysuite.com/download";
    return null;
  }

  return (
    <LayoutWrapper centered medium>
      <DocumentTitle title="Signup - Boxy Suite" />
      <Logo src={img.logos.suite_medium} alt="Boxy Suite" />
      <H2>Create Your Account</H2>
      <P1>Welcome, complete the registration to get started.</P1>
      <P1>You'll need the password you create below to log into the apps.</P1>
      <br />
      <Formik
        initialValues={{
          name: "",
          password: "",
          confirm_password: "",
          terms: false,
          privacy: false,
          newsletter: false
        }}
        validate={validateForm}
        onSubmit={onSubmit(secret, forceLogin, setRegistrationCompleted)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <Form error onSubmit={handleSubmit} loading={isSubmitting || loading}>
            <Popup
              trigger={
                <Form.Input
                  type="email"
                  name="email"
                  label="Your Email"
                  readOnly
                  value={email}
                />
              }
              content="The email you used during checkout, cannot be changed."
              position="left center"
              inverted
              basic
            />
            <Form.Input
              type="name"
              name="name"
              label="Your Name"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={errors.name && touched.name}
            />
            <Form.Input
              type="password"
              name="password"
              label="Password"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={errors.password && touched.password}
            />
            <ErrorMessage
              name="password"
              render={msg => <Message error content={msg} />}
            />
            <Form.Input
              type="password"
              name="confirm_password"
              label="Confirm Password"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirm_password}
              error={errors.confirm_password && touched.confirm_password}
            />
            <ErrorMessage
              name="confirm_password"
              render={msg => <Message error content={msg} />}
            />

            <CheckboxWithLabel
              label="I agree with the Terms of Service"
              name="terms"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.terms}
              error={errors.terms && touched.terms}
            >
              <P2>
                Read the{" "}
                <a
                  href="https://www.boxysuite.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
                .
              </P2>
            </CheckboxWithLabel>

            <CheckboxWithLabel
              name="privacy"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.privacy}
              label="I agree with the Privacy Policy"
              error={errors.privacy && touched.privacy}
            >
              <P2>
                Your Boxy Suite account email and password are securely stored
                on our services.
              </P2>
              <P2>
                We don't see or store the credentials that you use to log into
                your Google accounts within Boxy Suite apps.
              </P2>
              <P2>
                Before continuing, read the full{" "}
                <a
                  href="https://www.boxysuite.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </P2>
            </CheckboxWithLabel>

            <CheckboxWithLabel
              name="newsletter"
              label="Send me very occasional updates about new products via email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newsletter}
              error={errors.newsletter && touched.newsletter}
            />
            <br />
            <Button
              primary
              type="submit"
              disabled={isSubmitting}
              label="Continue"
            />
          </Form>
        )}
      </Formik>
    </LayoutWrapper>
  );
};
