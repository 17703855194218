import React, { useState } from "react";
import { H2, P1 } from "../../components/styled";
import Button from "../../components/Button";
import api from "../../dependencies/api";
import { Form, TextArea } from "semantic-ui-react";
import handleNetworkError from "../../utility/handleNetworkError";

export default props => {
  const { onCancel, subscriptionId, reasonId, setLoading } = props;

  const [userComment, setUserComment] = useState("");

  function handleCancelSubscription() {
    setLoading(true);

    api
      .cancelSubscription(subscriptionId, reasonId, userComment)
      .then(() => {
        alert("You have been successfully unsubscribed");
        setLoading(false);
        onCancel();
      })
      .catch(err => {
        setLoading(false);
        handleNetworkError(err);
      });
  }

  return (
    <>
      <H2>Goodbye 😢</H2>
      <P1>
        Thanks for trying Boxy Suite. If you don’t mind, let us know what didn’t
        work for you, this will help us improve the products in the future.
      </P1>

      <Form style={{ margin: "2em 0" }}>
        <TextArea
          placeholder="Tell us more"
          style={{ minHeight: 100 }}
          value={userComment}
          onChange={e => setUserComment(e.target.value)}
        />
      </Form>

      <br />

      <Button
        label="Keep Plan"
        style={{ marginRight: 10 }}
        onClick={onCancel}
      />
      <Button
        primary
        disabled={userComment.length === 0}
        label="Send and Unsubscribe"
        onClick={handleCancelSubscription}
      />
    </>
  );
};
