import React from "react";
import { AuthProvider } from "./context/AuthContext";
import AppRoot from "./AppRoot";
import DocumentTitle from "react-document-title";

export default () => {
  return (
    <AuthProvider>
      <DocumentTitle title="Boxy Suite" />
      <AppRoot />
    </AuthProvider>
  );
};
