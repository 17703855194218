import React from "react";
import Menu from "./Menu";
import LayoutWrapper from "./LayoutWrapper";

export default props => {
  const { loading } = props;

  return (
    <>
      <Menu />
      <LayoutWrapper loading={loading}>{props.children}</LayoutWrapper>
    </>
  );
};
