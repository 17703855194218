import account from "../resources/img/glyphs/account.svg";
import team from "../resources/img/glyphs/team.svg";
import invite from "../resources/img/glyphs/invite.svg";
import download from "../resources/img/glyphs/download.svg";
import support from "../resources/img/glyphs/support.svg";
import logout from "../resources/img/glyphs/logout.svg";
import edit from "../resources/img/glyphs/edit.svg";
import rowEdit from "../resources/img/glyphs/rowEdit.svg";

import suite_small from "../resources/img/logos/suite_small.png";
import suite_medium from "../resources/img/logos/suite_medium.png";
import appsumo from "../resources/img/logos/appsumo.png";

export default {
  logos: { suite_small, suite_medium, appsumo },
  glyphs: { account, team, invite, download, support, logout, edit, rowEdit }
};
