import React from "react";
import styled from "styled-components";
import { Dimmer, Loader, Dropdown } from "semantic-ui-react";
import img from "../utility/img";
import { TeamMember } from "../models/TeamMember";
import { capitalizeFirstChar } from "../utility/strings";
import copyToClipboard from "../utility/copyToClipboard";

const GridWrapper = styled.div`
  position: relative;
  margin: 2em 0;
  min-width: 460px;
`;

const Row = styled("div")<{ role: string; status: string }>`
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 2em;
  padding: 1em 0;

  cursor: pointer;

  img {
    opacity: 0;
  }
  :hover {
    img {
      opacity: 1;
    }
  }

  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  :first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.07);
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr 1fr 2em;
    span:first-child {
      display: none;
    }
  }

  ${props => props.role === "owner" && `color: #582EFF`};
  // ${props => props.status === "pending" && `opacity: .6`};
`;

interface TeamGridProps {
  members: TeamMember[];
  loading: boolean;
  removeMember?: (email: string) => void;
  resendInvitation?: (email: string) => void;
}

const TeamGrid: React.FC<TeamGridProps> = props => {
  const { members, loading, removeMember, resendInvitation } = props;

  return (
    <>
      <GridWrapper>
        <Dimmer active={loading} inverted>
          <Loader size="small">Loading</Loader>
        </Dimmer>
        {members.map((member, index) => (
          <Row key={`${index}`} role={member.role} status={member.status}>
            <span>{member.name || "..."}</span>
            <span>{member.email}</span>
            <span>
              {capitalizeFirstChar(member.role)}{" "}
              {member.status === "pending" && "(Pending)"}
            </span>
            <Dropdown
              direction="left"
              trigger={<img src={img.glyphs.rowEdit} alt="Edit" />}
              icon={null}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  key="remove"
                  onClick={() => {
                    const ans = confirm(
                      `Are you sure you want to remove ${member.name ||
                        member.email} from your team? They will lose access to Boxy Suite.`
                    );
                    if (ans) {
                      removeMember && removeMember(member.email);
                    }
                  }}
                >
                  <span style={{ color: "red" }}>Remove from Team</span>
                </Dropdown.Item>
                {/* only show if the user is not registerd (i.e. he has no name) */}
                {!member.name && (
                  <Dropdown.Item
                    key="resend-invitation"
                    onClick={() => {
                      const ans = confirm(
                        `This will send an email to ${member.name ||
                          member.email} with instructions on how to join the team? Do you want to proceed?`
                      );
                      if (ans) {
                        resendInvitation && resendInvitation(member.email);
                      }
                    }}
                  >
                    Resend Invitation Email
                  </Dropdown.Item>
                )}
                {!member.name && member.invitation_link && (
                  <Dropdown.Item
                    key="copy-invitation-link"
                    onClick={() => copyToClipboard(member.invitation_link)}
                  >
                    Copy Invitation Link
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Row>
        ))}
      </GridWrapper>
    </>
  );
};

export default TeamGrid;
