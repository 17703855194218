import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "semantic-ui-react";
import Button from "./Button";

const InputWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: 22em auto;
  grid-gap: 0.5em;
  margin: 1em 0;
  @media (max-width: 760px) {
    grid-template-columns: 100%;
    width: 100%;
  }
`;

interface InputFieldWithButtonProps {
  value: string;
  action: string;
  placeholder?: string;
  disabled?: boolean;
  onClick?: (value: string) => void;
  onChange?: (value: string) => void;
}

const InputFieldWithButton: React.FC<InputFieldWithButtonProps> = props => {
  return (
    <InputWrapper>
      <Input
        placeholder={props.placeholder}
        value={props.value}
        onChange={e => {
          props.onChange && props.onChange(e.target.value);
        }}
        onKeyPress={(e: any) => {
          if (e.key === "Enter") {
            !props.disabled && props.onClick && props.onClick(props.value);
          }
        }}
        fluid
      />
      <Button
        primary
        label={props.action}
        disabled={props.disabled}
        onClick={() => {
          props.onClick && props.onClick(props.value);
        }}
      />
    </InputWrapper>
  );
};

export default InputFieldWithButton;
