import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";

function useRefreshUserOnce() {
  const [refreshed, setRefreshed] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (refreshed || refreshing) {
      return;
    }

    setRefreshing(true);

    authContext.refreshUser().then(() => {
      setRefreshed(true);
      setRefreshing(false);
    });
  });
}

export default useRefreshUserOnce;
