export default backend_status => {
  return (
    {
      active: "Active",
      deleted: "Deleted",
      trialing: "Trialing",
      past_due: "Past Due"
    }[backend_status] || backend_status
  );
};
