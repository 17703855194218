import React, { useContext } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import { Form } from "semantic-ui-react";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import LayoutWrapper from "../components/LayoutWrapper";
import img from "../utility/img";
import handleNetworkError from "../utility/handleNetworkError";
import { AuthContext } from "../context/AuthContext";
import DocumentTitle from "react-document-title";

const validateForm = values => {
  let errors = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Required";
  }

  return errors;
};

const Logo = styled.img`
  display: block;
  margin: 0 auto 3em;
  width: 7em;
`;

const onSubmit = login => {
  return async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      await login(values.email, values.password);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      handleNetworkError(error);
    }
  };
};

function parseQuery(queryString) {
  const query = {};
  const pairs = (queryString[0] === "?"
    ? queryString.slice(1)
    : queryString
  ).split("&");
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

export default props => {
  const { login } = useContext(AuthContext);

  const { email } = parseQuery(window.location.search);

  return (
    <LayoutWrapper centered small>
      <DocumentTitle title="Login - Boxy Suite" />
      <Logo src={img.logos.suite_medium} alt="Boxy Suite" />
      <Formik
        initialValues={{ email: email || "", password: "" }}
        validate={validateForm}
        onSubmit={onSubmit(login)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <StyledForm error onSubmit={handleSubmit} loading={isSubmitting}>
            <Form.Input
              type="email"
              name="email"
              label="Email"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email && touched.email}
            />
            <Form.Input
              type="password"
              name="password"
              label="Password"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={errors.password && touched.password}
            />
            <Button
              primary
              type="submit"
              disabled={isSubmitting}
              label="Login"
            />
            <br />
            <Link to={"/forgot"}>Forgot Password?</Link>
          </StyledForm>
        )}
      </Formik>
    </LayoutWrapper>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  margin: auto;
  flex-direction: column;
  width: 320px;
  align-items: center;
  div.field {
    width: 100%;
  }
  button {
    align-self: flex-end;
    margin-top: 5px;
  }
  a {
    align-self: flex-end;
  }
`;
