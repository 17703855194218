import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import img from "../utility/img";
import { AuthContext } from "../context/AuthContext";

const menuItemsPrimary = isUserTeamOwner => {
  const basic = [
    {
      title: "Account",
      href: "/",
      exact: true,
      icon: img.glyphs.account
    },
    // {
    //   title: "Invite",
    //   href: "/invite",
    //   icon: img.glyphs.invite
    // },
    {
      title: "Download",
      href: "/download",
      icon: img.glyphs.download
    }
  ];

  if (isUserTeamOwner) {
    const teamItem = {
      title: "Team",
      href: "/team",
      icon: img.glyphs.team
    };

    basic.splice(1, 0, teamItem);
  }

  return basic;
};

const Container = styled.div`
  position: sticky;
  top: 0;
  width: auto;
  background: #582eff;
  padding: 1.7em;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  float: left;
  min-height: 100vh;
`;

const RowsWrapper = styled.div`
  :last-of-type {
    > *:last-child {
      > *:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  > a,
  > div {
    opacity: 0.7;
    transition: opacity 0.15s;
    display: block;
    :hover {
      opacity: 1;
    }
  }
`;

const FakeNavLink = styled.div``;

const RowContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  color: white;
  margin-bottom: 1.2em;
  img {
    margin-top: -1px;
    width: 22px;
    height: 22px;
  }
  span {
    margin-left: 0.8rem;
  }
  :hover {
    opacity: 1;
  }
  @media (max-width: 800px) {
    span {
      display: none;
    }
  }
`;

const UnreadBadge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 1.4em;
  max-height: 1.4em;
  border-radius: 0.8em;
  background: rgb(225, 0, 0);
  color: white;
  font-size: 0.8em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    font-size: 0.7em;
    top: -0.4em;
    right: -0.4em;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
  margin-bottom: 1.7em;
  font-weight: 700;
  font-size: 1.3em;
  opacity: 1 !important;
  img {
    margin-bottom: -4px;
    width: 22px;
    height: 22px;
    transform: scale(1.3);
  }
  span {
    font-family: Archivo;
    font-weight: 600;
    margin-left: 0.8rem;
    margin-right: 3rem;
    font-size: 0.8em;
  }
  @media (max-width: 800px) {
    span {
      display: none;
    }
  }
`;

const ActiveStyle = {
  fontWeight: 600,
  opacity: 1
};

export default props => {
  const { logout, user } = useContext(AuthContext);

  if (!user) {
    return null;
  }

  const isUserTeamOwner = user.team_ownership !== null;
  const invitesLeft = 1;
  // TODO: a context should provide the number of invites left to get the lifetime license

  return (
    <Container>
      <RowsWrapper>
        <LogoContainer>
          {/* TODO: this should be a clickable link to '/' */}
          <img src={img.logos.suite_small} alt="Boxy Suite" />
          <span>BOXYSUITE</span>
        </LogoContainer>
        {menuItemsPrimary(isUserTeamOwner).map(item => (
          <NavLink
            key={item.title}
            to={item.href}
            exact={item.exact}
            activeStyle={ActiveStyle}
          >
            <RowContainer>
              <img src={item.icon} alt={item.title} />
              <span>{item.title}</span>
              {item.title === "Invite" && (
                <UnreadBadge>{invitesLeft}</UnreadBadge>
              )}
            </RowContainer>
          </NavLink>
        ))}
      </RowsWrapper>
      <RowsWrapper>
        {/* Support */}

        <a
          href={
            "https://www.notion.so/superlinear/Boxy-Suite-Support-94b7ac55c3d043868a6b0fb97b052e1e"
          }
          target="blank"
          rel="noopener noreferrer"
        >
          <RowContainer>
            <img src={img.glyphs.support} alt={"Support"} />
            <span>Support</span>
          </RowContainer>
        </a>

        {/* Logout */}
        <FakeNavLink>
          <RowContainer onClick={logout}>
            <img src={img.glyphs.logout} alt={"Logout"} />
            <span>Logout</span>
          </RowContainer>
        </FakeNavLink>
      </RowsWrapper>
    </Container>
  );
};
