import React from "react";
import styled from "styled-components";
import { SmallLabel, Field } from "./styled";
import img from "../utility/img";
import InlineButton from "./InlineButton";
import PropTypes from "prop-types";

const FieldWrapper = styled.div`
  div {
    display: inline-flex;
    img {
      margin-left: 0.5em;
      opacity: 0;
    }
    :hover {
      img {
        opacity: 1;
      }
    }
  }

  ${props => props.editable && `cursor: pointer;`}
`;

const AccountsField = props => {
  const { label, value, editable, actionLabel, onActionClick } = props;

  return (
    <FieldWrapper editable={editable}>
      <SmallLabel>
        {label}{" "}
        {actionLabel && (
          <InlineButton label={actionLabel} onClick={onActionClick} />
        )}
      </SmallLabel>
      <div>
        <Field>{value}</Field>
        {editable && <img src={img.glyphs.edit} alt="Edit" />}
      </div>
    </FieldWrapper>
  );
};

AccountsField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  actionLabel: PropTypes.string,
  onActionClick: PropTypes.func
};

export default AccountsField;
