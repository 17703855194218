import React, { useState } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import { Form, Message } from "semantic-ui-react";
import Button from "../components/Button";
import LayoutWrapper from "../components/LayoutWrapper";
import img from "../utility/img";
import handleNetworkError from "../utility/handleNetworkError";
import DocumentTitle from "react-document-title";
import { H2, P2 } from "../components/styled";
import api from "../dependencies/api";

const validateForm = values => {
  let errors = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};

const Logo = styled.img`
  display: block;
  margin: 0 auto 3em;
  width: 7em;
`;

const onSubmit = setSuccess => {
  return async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      await api.askResetPassword(values.email);
      setSuccess(true);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      handleNetworkError(error);
    }
  };
};

export default () => {
  const [success, setSuccess] = useState(false);

  return (
    <LayoutWrapper centered small>
      <DocumentTitle title="Reset Password - Boxy Suite" />
      <Logo src={img.logos.suite_medium} alt="Boxy Suite" />

      {success && (
        <Message>
          <p>
            If the email you typed is right you should receive a link to reset
            your password in your inbox shortly.
          </p>
        </Message>
      )}

      <H2>Reset Password</H2>
      <P2>
        Type your email below and we will send you a link to reset your
        password.
      </P2>

      <Formik
        initialValues={{ email: "", password: "" }}
        validate={validateForm}
        onSubmit={onSubmit(setSuccess)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <StyledForm error onSubmit={handleSubmit} loading={isSubmitting}>
            <Form.Input
              type="email"
              name="email"
              label="Your Email"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email && touched.email}
            />
            <Button
              primary
              type="submit"
              disabled={isSubmitting}
              label="Send Reset Password Link"
            />
            <br />
          </StyledForm>
        )}
      </Formik>
    </LayoutWrapper>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  margin: auto;
  flex-direction: column;
  margin-top: 20px;
  div.field {
    width: 100%;
  }
  button {
    align-self: flex-end;
    margin-top: 5px;
  }
`;
