import React, { useState, useEffect, useContext } from "react";
import { H2, AccountsGrid, P2 } from "../components/styled";
import AccountsField from "../components/AccountsField";
import InputFieldWithButton from "../components/InputFieldWithButton";
import TeamGrid from "../components/TeamGrid";
import AdminPage from "../components/AdminPage";
import { Dimmer, Loader } from "semantic-ui-react";
import api from "../dependencies/api";
import { AuthContext } from "../context/AuthContext";
import handleNetworkError from "../utility/handleNetworkError";
import { FullTeamInfo } from "../models/TeamInfo";
import SingleInputModal from "../components/modals/SingleInputModal";
import Button from "../components/Button";
import SimpleModal from "../components/SimpleModal";
import openURL from "../utility/openURL";
import DocumentTitle from "react-document-title";
import openURLCurrentTab from "../utility/openURLCurrentTab";

const Team: React.FC<any> = props => {
  const { user } = useContext(AuthContext);

  const [fullLoading, setFullLoading] = useState(true);
  const [teamGridLoading, setTeamGridLoading] = useState(false);
  const [emailBeingTyped, setEmailBeingTyped] = useState("");
  const [teamInfo, setTeamInfo] = useState<FullTeamInfo | null>(null);
  const [showTeamNameModal, setShowTeamNameModal] = useState(false);
  const [teamNameModalLoading, setTeamNameModalLoading] = useState(false);
  const [showSeatsModal, setShowSeatsModal] = useState(false);

  if (!user) {
    return null;
  }

  useEffect(
    () => {
      if (!user.team_ownership) {
        return;
      }

      api
        .getTeamInfo(user.team_ownership.id)
        .then(info => {
          setTeamInfo(info);
          setFullLoading(false);
        })
        .catch(err => {
          handleNetworkError(err);
          setFullLoading(false);
        });
    },
    [user.team_ownership]
  );

  const addTeamMember = (email: string) => {
    if (!teamInfo) {
      return;
    }

    setTeamGridLoading(true);

    api
      .addTeamMember(teamInfo.id, email)
      .then(updatedTeamInfo => {
        setTeamInfo(updatedTeamInfo);
        setTeamGridLoading(false);
        setEmailBeingTyped("");
      })
      .catch(error => {
        handleNetworkError(error);
        setTeamGridLoading(false);
      });
  };

  const removeTeamMember = (email: string) => {
    if (!teamInfo) {
      return;
    }

    setTeamGridLoading(true);

    api
      .removeTeamMember(teamInfo.id, email)
      .then(updatedTeamInfo => {
        setTeamInfo(updatedTeamInfo);
        setTeamGridLoading(false);
      })
      .catch(error => {
        handleNetworkError(error);
        setTeamGridLoading(false);
      });
  };

  const resendInvitationEmail = (email: string) => {
    if (!teamInfo) {
      return;
    }

    setTeamGridLoading(true);

    api
      .resendTeamInvitationEmail(teamInfo.id, email)
      .then(updatedTeamInfo => {
        setTeamInfo(updatedTeamInfo);
        setTeamGridLoading(false);
        setEmailBeingTyped("");
      })
      .catch(error => {
        handleNetworkError(error);
        setTeamGridLoading(false);
      });
  };

  const handleChangeTeamName = (newTeamName: string) => {
    if (!teamInfo) {
      return;
    }

    setTeamNameModalLoading(true);

    api
      .changeTeamName(teamInfo.id, newTeamName)
      .then(info => {
        setTeamInfo(info);
        setTeamNameModalLoading(false);
        setShowTeamNameModal(false);
      })
      .catch(error => {
        setTeamNameModalLoading(false);
        handleNetworkError(error);
      });
  };

  return (
    <AdminPage loading={fullLoading}>
      <DocumentTitle title="Team - Boxy Suite" />
      <SingleInputModal
        show={showTeamNameModal}
        title={"Update Team Name"}
        loading={teamNameModalLoading}
        inputInitialValue={(teamInfo && teamInfo.name) || ""}
        onCancel={() => setShowTeamNameModal(false)}
        onConfirm={handleChangeTeamName}
      />
      <SimpleModal
        show={showSeatsModal}
        medium
        title="Add more seats"
        description="Please contact us to add more seats to your team. We handle this process manually."
        actionLabel="Contact us"
        cancelLabel="Cancel"
        onActionClick={() => {
          openURLCurrentTab(
            "mailto:support@boxyteam.com?subject=Add%20more%20seats%20to%20my%20Boxy%20Suite%20team"
          );
        }}
        onCancelClick={() => setShowSeatsModal(false)}
      />

      <H2>My Team</H2>
      <AccountsGrid>
        <AccountsField
          label="Team Name"
          value={(teamInfo && teamInfo.name) || "Untitled"}
          actionLabel="Edit"
          onActionClick={() => setShowTeamNameModal(true)}
        />
        <AccountsField
          label="Total Seats"
          value={(teamInfo && `${teamInfo.seats}`) || "..."}
        />
        <AccountsField
          label="Available Seats"
          value={(teamInfo && `${teamInfo.available_seats}`) || "..."}
          actionLabel="Add Seats"
          onActionClick={() => setShowSeatsModal(true)}
        />
      </AccountsGrid>
      <H2>Manage Team</H2>
      <P2>
        You can invite anyone to join your team, just type their email below and
        press enter.
      </P2>
      <InputFieldWithButton
        placeholder="your@teammate.com"
        action="Add to Team"
        value={emailBeingTyped}
        onChange={setEmailBeingTyped}
        disabled={teamGridLoading}
        onClick={addTeamMember}
      />
      <TeamGrid
        members={(teamInfo && teamInfo.members) || []}
        loading={teamGridLoading}
        removeMember={removeTeamMember}
        resendInvitation={resendInvitationEmail}
      />
    </AdminPage>
  );
};

export default Team;
