import React, { useState } from "react";
import styled from "styled-components";
import { Formik, ErrorMessage } from "formik";
import { Form, Message } from "semantic-ui-react";
import Button from "../components/Button";
import LayoutWrapper from "../components/LayoutWrapper";
import img from "../utility/img";
import handleNetworkError from "../utility/handleNetworkError";
import DocumentTitle from "react-document-title";
import { H2 } from "../components/styled";
import api from "../dependencies/api";
import validateRequiredKeys from "../utility/validateRequiredKeys";
import { Link } from "react-router-dom";

const validateForm = values => {
  let errors = validateRequiredKeys(values, ["password", "confirm_password"]);

  if (values.password !== values.confirm_password) {
    errors.confirm_password = "Not the same as Password";
  }

  if (values.password && values.password.length < 6) {
    errors.password = "Password must be at least 6 characters long";
  }

  return errors;
};

const Logo = styled.img`
  display: block;
  margin: 0 auto 3em;
  width: 7em;
`;

const onSubmit = (setSuccess, secret) => {
  return async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      await api.doResetPassword(secret, values.password);
      setSuccess(true);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      handleNetworkError(error);
    }
  };
};

export default props => {
  const [success, setSuccess] = useState(false);

  const { secret } = props.match.params;

  return (
    <LayoutWrapper centered small>
      <DocumentTitle title="Reset Password - Boxy Suite" />
      <Logo src={img.logos.suite_medium} alt="Boxy Suite" />

      {success && (
        <Message>
          <p>
            Password correctly reset, you can now proceed to{" "}
            <Link to={"/login"}>login</Link>.
          </p>
        </Message>
      )}

      <H2>Set your new password</H2>

      <Formik
        initialValues={{ password: "", confirm_password: "" }}
        validate={validateForm}
        onSubmit={onSubmit(setSuccess, secret)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <StyledForm error onSubmit={handleSubmit} loading={isSubmitting}>
            <Form.Input
              type="password"
              name="password"
              label="New Password"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={errors.password && touched.password}
            />
            <ErrorMessage
              name="password"
              render={msg =>
                msg !== "required" && <Message error content={msg} />
              }
            />
            <Form.Input
              type="password"
              name="confirm_password"
              label="Confirm Password"
              placeholder=""
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirm_password}
              error={errors.confirm_password && touched.confirm_password}
            />
            <ErrorMessage
              name="confirm_password"
              render={msg =>
                msg !== "required" && <Message error content={msg} />
              }
            />
            <Button
              primary
              type="submit"
              disabled={isSubmitting}
              label="Reset Password"
            />
            <br />
          </StyledForm>
        )}
      </Formik>
    </LayoutWrapper>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  margin: auto;
  flex-direction: column;
  margin-top: 20px;
  div.field {
    width: 100%;
  }
  button {
    align-self: flex-end;
    margin-top: 5px;
  }
`;
