import axios, { AxiosInstance } from "axios";
import {
  GetSignupInfoResponse,
  LoginResponse,
  AppSumoSignupResponse
} from "../models/ApiResponses";
import User from "../models/User";
import { FullTeamInfo } from "../models/TeamInfo";

class ApiManager {
  public logoutHandler?: () => void;
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: "https://accounts.boxysuite.com/web-api/v1",
      timeout: 10000
    });

    this.instance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          if (this.logoutHandler) {
            this.logoutHandler();
            return;
          }
        }

        throw error;
      }
    );
  }

  public setAuthToken(token: string) {
    this.instance.defaults.headers.common["Authorization"] = token;
  }

  public async getSignupInfo(secret: string): Promise<GetSignupInfoResponse> {
    const res = await this.instance.get(`/signup/${secret}`);
    return res.data;
  }

  public async completeAppSumoSignup(
    code: string,
    email: string,
    name: string,
    password: string,
    newsletter: boolean
  ): Promise<AppSumoSignupResponse> {
    const res = await this.instance.post("/appsumo/signup", {
      code,
      name,
      email,
      password,
      newsletter
    });

    return res.data;
  }

  public async completeSignup(
    secret: string,
    name: string,
    password: string,
    newsletter: boolean
  ): Promise<LoginResponse> {
    const res = await this.instance.post(`/signup/${secret}`, {
      name,
      password,
      newsletter
    });
    return res.data;
  }

  public async login(email: string, password: string): Promise<LoginResponse> {
    const res = await this.instance.post("/login", { email, password });
    return res.data;
  }

  public async getUser(): Promise<User> {
    const res = await this.instance.get("/user");
    return res.data;
  }

  public async getTeamInfo(teamID: string): Promise<FullTeamInfo> {
    const res = await this.instance.get(`/team/${teamID}`);
    return res.data;
  }

  public async addTeamMember(
    teamID: string,
    email: string
  ): Promise<FullTeamInfo> {
    const res = await this.instance.post(`/team/${teamID}/add`, { email });
    return res.data;
  }

  public async removeTeamMember(
    teamID: string,
    email: string
  ): Promise<FullTeamInfo> {
    const res = await this.instance.post(`/team/${teamID}/remove`, { email });
    return res.data;
  }

  public async resendTeamInvitationEmail(
    teamID: string,
    email: string
  ): Promise<FullTeamInfo> {
    const res = await this.instance.post(`/team/${teamID}/resend_invite`, {
      email
    });
    return res.data;
  }

  public async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    await this.instance.post("/user/change_password", {
      old_password: oldPassword,
      new_password: newPassword
    });
  }

  public async changeTeamName(
    teamID: string,
    newTeamName: string
  ): Promise<FullTeamInfo> {
    const res = await this.instance.post(`/team/${teamID}/change_name`, {
      team_name: newTeamName
    });
    return res.data;
  }

  public async askResetPassword(email: string): Promise<void> {
    await this.instance.post("/reset_password", { email });
  }

  public async doResetPassword(token: string, password: string): Promise<void> {
    await this.instance.post("/do_reset_password", {
      token,
      new_password: password
    });
  }

  public async redeemOffer(subscriptionID: string, offerName: string) {
    await this.instance.post("/user/redeem_offer", {
      subscription_id: subscriptionID,
      offer_name: offerName
    });
  }

  public async cancelSubscription(
    subscriptionID: string,
    reasonID: string,
    cancelReason: string
  ) {
    await this.instance.post("/user/cancel_subscription", {
      subscription_id: subscriptionID,
      reason_id: reasonID,
      cancel_reason: cancelReason
    });
  }
}

const sharedInstance = new ApiManager();

export default sharedInstance;
