import React from "react";
import { H2, P1 } from "../../components/styled";
import Button from "../../components/Button";
import api from "../../dependencies/api";
import handleNetworkError from "../../utility/handleNetworkError";

export default props => {
  const { onNext, subscriptionId, setLoading } = props;

  function handleApplyDiscount() {
    setLoading(true);

    api
      .redeemOffer(subscriptionId, "halve_sub_price")
      .then(() => {
        setLoading(false);
        onNext(true);
      })
      .catch(err => {
        setLoading(false);
        handleNetworkError(err);
      });
  }

  return (
    <>
      <H2>A special offer for you, 50% OFF</H2>
      <P1>
        We’re committed to keep improving Boxy Suite, that’s why{" "}
        <strong>we’d love to keep you onboard</strong>.
      </P1>
      <P1>
        Can you consider this special offer? It's applied on top of any existing
        discounts you already have.
      </P1>

      <br />

      <Button
        label="Cancel Anyway"
        style={{ marginRight: 10 }}
        onClick={() => onNext(false)}
      />

      <Button
        primary
        label="Apply 50% Discount"
        onClick={handleApplyDiscount}
      />
    </>
  );
};
