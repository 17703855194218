import React, { useState, useEffect } from "react";
import { H2, P1 } from "../../components/styled";
import Button from "../../components/Button";
import { Form, Radio } from "semantic-ui-react";

export default props => {
  const { onCancel, onNext, onReasonChange } = props;

  const options = [
    {
      label: "I’m not convinced yet",
      value: "not_convinced"
    },
    {
      label: "It doesn’t have the features I need",
      value: "features"
    },
    {
      label: "It’s too expensive",
      value: "expensive"
    },
    {
      label: "It’s not working properly",
      value: "buggy"
    }
  ];

  const [selectedValue, setSelectedValue] = useState("");
  const [continueDisabled, setContinueDisabled] = useState(true);

  useEffect(
    () => {
      if (selectedValue !== "") {
        setContinueDisabled(false);
      } else {
        setContinueDisabled(true);
      }
    },
    [selectedValue]
  );

  function handleChange(e, { value }) {
    setSelectedValue(value);
    onReasonChange(value);
  }

  return (
    <>
      <H2>We're sorry to see you go</H2>
      <P1>Can you tell us why you’re canceling your Boxy Suite plan?</P1>

      <Form style={{ margin: "2em 0" }}>
        {options.map(option => (
          <Form.Field key={option.value}>
            <Radio
              label={option.label}
              name="radioGroup"
              value={option.value}
              checked={selectedValue === option.value}
              onChange={handleChange}
            />
          </Form.Field>
        ))}
      </Form>

      <Button label="Cancel" style={{ marginRight: 10 }} onClick={onCancel} />
      <Button
        primary
        disabled={continueDisabled}
        label="Continue"
        onClick={onNext}
      />
    </>
  );
};
