import React from "react";
import { H2, P1 } from "../../components/styled";
import Button from "../../components/Button";
import api from "../../dependencies/api";
import handleNetworkError from "../../utility/handleNetworkError";

export default props => {
  const { onNext, setLoading, subscriptionId } = props;

  const extendTrialClicked = () => {
    setLoading(true);

    api
      .redeemOffer(subscriptionId, "extend_trial")
      .then(res => {
        setLoading(false);
        onNext(true);
      })
      .catch(err => {
        setLoading(false);
        handleNetworkError(err);
      });
  };

  return (
    <>
      <H2>Would you like to have more time?</H2>
      <P1>
        We’d like to offer your <strong>2 extra weeks of free trial</strong>. We
        believe that trying out the product a bit more might help you make a
        more informed decision.
      </P1>

      <br />

      <Button
        label="Cancel Anyway"
        style={{ marginRight: 10 }}
        onClick={() => onNext(false)}
      />
      <Button primary label="Extend Trial" onClick={extendTrialClicked} />
    </>
  );
};
