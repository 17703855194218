export default (error, showErrorCode = false) => {
  const message = (() => {
    if (error.response && error.response.data) {
      const data = error.response.data;

      if (showErrorCode) {
        return `${data.user_msg} (error code: ${data.error_code})`;
      } else {
        return `${data.user_msg}`;
      }
    }

    return error.message || "Network error";
  })();

  alert(message);
};
