import React from "react";
import AdminPage from "../components/AdminPage";
import { H2, P2 } from "../components/styled";
import { Link } from "react-router-dom";
import DocumentTitle from "react-document-title";

const NotFoundPage = () => {
  return (
    <AdminPage>
      <DocumentTitle title="Not Found - Boxy Suite" />
      <H2>Not Found</H2>
      <P2>
        Whathever you where looking for is not here. Please contact support or{" "}
        <Link to={"/"}>go back home</Link>.
      </P2>

      <img src="https://boxyteam-static.s3.amazonaws.com/images/confused-travolta.gif" />
    </AdminPage>
  );
};

export default NotFoundPage;
