interface ErrorsDictionary {
  [key: string]: string;
}

/**
 * Checks that `object` has a value for all the keys specified in `requiredKeys` and returns
 * an errors dictionary, with "required" value for all the missing keys
 */
export default function validateRequiredKeys(
  object: any,
  requiredKeys: string[]
): ErrorsDictionary {
  const errors: ErrorsDictionary = {};

  requiredKeys.forEach(key => {
    if (!object[key]) {
      errors[key] = "required";
    }
  });

  return errors;
}
