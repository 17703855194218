import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Route, Redirect } from "react-router-dom";

/**
 * A route only accessible to a user that's not logged in. If he is logged in, he is redirect to "/".
 */
export default ({ component: Component, ...rest }) => {
  const { isLoggedIn, user } = useContext(AuthContext);

  return !isLoggedIn || !user ? (
    <Route component={Component} {...rest} />
  ) : (
    <Redirect to={"/"} />
  );
};
