import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ButtonWrapper = styled.button`
  border: none;
  border-radius: 4px;
  cursor: pointer;

  font-weight: 600;
  padding: 0.9em 1.5em;

  /* secondary style by default */
  color: #582eff;
  background: rgba(88, 46, 255, 0.1);
  :hover {
    filter: brightness(1.4);
  }

  outline: none;

  ${props =>
    props.primary &&
    `
    color: white;
    background: #582EFF;
    :hover {
      filter: brightness(1.15);
    }
  `}

  ${props =>
    props.right &&
    `
    float: right;
    margin-left: .5em;
  `}

  ${props =>
    props.disabled &&
    `
    opacity: 0.7;
    cursor: not-allowed;
  `}
`;

const Button = props => {
  const { label, onClick } = props;

  return (
    <ButtonWrapper onClick={onClick} {...props}>
      {label}
    </ButtonWrapper>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  right: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Button;
