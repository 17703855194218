import User from "../models/User";
import api from "./api";

class AuthManager {
  private static AUTH_TOKEN_KEY = "auth:token";
  private static USER_KEY = "auth:user";

  public loginStateChangeHandler?: () => void;

  constructor() {
    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);
    this.forceLogin = this.forceLogin.bind(this);
  }

  get isLoggedIn(): boolean {
    return this.authToken != null && this.authToken.length > 0;
  }

  public async login(email: string, password: string) {
    const data = await api.login(email, password);
    this.authToken = data.token;
    this.user = data.user;
    api.setAuthToken(this.authToken);
    this.invokeLoginStateChangeHandler();
  }

  public forceLogin(token: string, user: User) {
    this.authToken = token;
    this.user = user;
    api.setAuthToken(this.authToken);
    this.invokeLoginStateChangeHandler();
  }

  public logout() {
    this.authToken = null;
    this.user = null;
    this.invokeLoginStateChangeHandler();
  }

  get authToken(): string | null {
    return localStorage.getItem(AuthManager.AUTH_TOKEN_KEY);
  }

  set authToken(value: string | null) {
    value
      ? localStorage.setItem(AuthManager.AUTH_TOKEN_KEY, value)
      : localStorage.removeItem(AuthManager.AUTH_TOKEN_KEY);
  }

  get user(): User | null {
    const raw = localStorage.getItem(AuthManager.USER_KEY);

    if (raw) {
      try {
        return JSON.parse(raw);
      } catch {
        return null;
      }
    }

    return null;
  }

  set user(value: User | null) {
    if (value) {
      localStorage.setItem(AuthManager.USER_KEY, JSON.stringify(value));
    } else {
      localStorage.removeItem(AuthManager.USER_KEY);
    }
  }

  private invokeLoginStateChangeHandler() {
    if (this.loginStateChangeHandler) {
      this.loginStateChangeHandler();
    }
  }
}

function integrateWithApiManager() {
  const token = sharedInstance.authToken;
  if (token) {
    api.setAuthToken(token);
  }

  api.logoutHandler = () => {
    sharedInstance.logout();
  };
}

const sharedInstance = new AuthManager();
integrateWithApiManager();

export default sharedInstance;
