import React from "react";
import api from "../dependencies/api";
import authManager from "../dependencies/authManager";
import User from "../models/User";

interface AuthContextValue {
  isLoggedIn: boolean;
  user: User | null;
  // login with the provided username and password
  login: (username: string, password: string) => Promise<void>;
  // logout
  logout: () => void;
  // performs a request to the backend to get an updated user
  refreshUser: () => Promise<void>;
  // force login, for when you already got a token and user
  forceLogin: (token: string, user: User) => void;
}

const AuthContext = React.createContext<AuthContextValue>(null as any);

class AuthProvider extends React.Component<
  any,
  {
    isLoggedIn: boolean;
    user: User | null;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { isLoggedIn: authManager.isLoggedIn, user: authManager.user };
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.refreshUser = this.refreshUser.bind(this);
    this.forceLogin = this.forceLogin.bind(this);
  }

  public async login(email: string, password: string) {
    await authManager.login(email, password);
  }

  public forceLogin(token: string, user: User) {
    authManager.forceLogin(token, user);
  }

  public logout() {
    authManager.logout();
  }

  public async refreshUser() {
    const user = await api.getUser();
    authManager.user = user;
    this.setState({ user });
  }

  public componentDidMount() {
    authManager.loginStateChangeHandler = () => {
      this.syncStateWithAuthManager();
    };
  }

  public render() {
    return (
      <AuthContext.Provider
        value={{
          isLoggedIn: this.state.isLoggedIn,
          user: this.state.user,
          login: this.login,
          logout: this.logout,
          refreshUser: this.refreshUser,
          forceLogin: this.forceLogin
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }

  private syncStateWithAuthManager() {
    this.setState({
      isLoggedIn: authManager.isLoggedIn,
      user: authManager.user
    });
  }
}

export { AuthContext, AuthProvider };
