/**
 * Makes the first character of the string uppercase.
 * @param value The string to capitalize
 */
export function capitalizeFirstChar(value: string): string {
  if (value.length === 0) {
    return value;
  }
  if (value.length === 1) {
    return value.toUpperCase();
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
}
