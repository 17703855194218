import React from "react";
import { H2, P1 } from "../../components/styled";
import Button from "../../components/Button";

export default props => {
  const { onDismiss } = props;

  return (
    <>
      <H2>🎉 &nbsp;Enjoy 2 extra weeks of free trial</H2>
      <P1>
        Try out the product a bit more and see if it's right for you. Feel free
        to reach out to us for any feedback or questions.
      </P1>

      <br />

      <Button primary label="OK" onClick={onDismiss} />
    </>
  );
};
