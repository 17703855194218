import React from "react";
import styled from "styled-components";
import { Loader, Dimmer } from "semantic-ui-react";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  transition: 0.2s cubic-bezier(0.3, 0, 0.2, 1);
  ${props =>
    !props.show &&
    `
    opacity: 0; 
    pointer-events: none;
    > div:last-child {
      transform: scale(.95);
    }
  `}
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: -1;
`;

const Modal = styled.div`
  position: relative;
  width: 30em;
  ${props => props.small && `width: 20em`};
  ${props => props.big && `width: 36em`};
  max-width: 100%;
  padding: 2em;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 5px 70px -20px rgba(0, 0, 0, 0.3);
  transition: 0.2s cubic-bezier(0.3, 0, 0.2, 1);
  > h2:first-of-type {
    margin-top: 0;
  }
`;

export default props => {
  const { show, loading } = props;
  const otherProps = { ...props, show: undefined, loading: undefined };

  return (
    <ModalWrapper show={show}>
      <Backdrop />
      <Modal {...otherProps}>
        {props.children}
        <Dimmer active={loading} inverted style={{ borderRadius: 8 }}>
          <Loader size="small" />
        </Dimmer>
      </Modal>
    </ModalWrapper>
  );
};
