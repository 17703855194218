import React, {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";
import AccountsField from "../components/AccountsField";
import AdminPage from "../components/AdminPage";
import ChangePasswordModal from "../components/modals/ChangePasswordModal";
import { AccountsGrid, H2, P1 } from "../components/styled";
import { AuthContext } from "../context/AuthContext";
import useRefreshUserOnce from "../hooks/useRefreshUserOnce";
import LifetimePurchaseData from "../models/LifetimePurchaseData";
import Subscription from "../models/Subscription";
import currencySymbol from "../utility/currencySymbols";
import formatSubscriptionStatus from "../utility/formatSubscriptionStatus";
import openURL from "../utility/openURL";
import api from "../dependencies/api";
import handleNetworkError from "../utility/handleNetworkError";
import DocumentTitle from "react-document-title";
import User from "../models/User";
import { BasicTeamInfo } from "../models/TeamInfo";
import { Link } from "react-router-dom";
import CancelFlow from "./CancelFlow/CancelFlow";
import ModalWrapper from "../components/ModalWrapper";
import Button from "../components/Button";

// Needed to use the Paddle global variable without TS bitching about it
declare var Paddle: any;

// **Must** be false in production, set to true for testing
const forceShowResubscribeOption = false;
const forceShowConvertToLifetimeOption = false;

const subscriptionToRow = (
  user: User,
  sub: Subscription,
  setSubscriptionBeingCancelled: (sub: Subscription | null) => void
) => {
  return (
    <div
      key={`${sub.subscription_id}`}
      style={{ order: sub.status !== "deleted" ? (sub.is_team ? 0 : 1) : 2 }}
    >
      <H2>{`${sub.is_team ? "Team" : ""} Subscription`}</H2>
      <AccountsGrid columns={3}>
        {/* Next payment */}
        {sub.status !== "deleted" && (
          <AccountsField
            label="Next Payment"
            value={`${currencySymbol(sub.currency)}${
              sub.next_bill_amount === null
                ? sub.unit_price
                : sub.next_bill_amount
            } (${sub.next_bill_date})`}
          />
        )}
        {/* Status */}
        <AccountsField
          label="Status"
          value={formatSubscriptionStatus(sub.status)}
          actionLabel={(sub.status !== "deleted" && "Cancel") || ""}
          onActionClick={() => {
            setSubscriptionBeingCancelled(sub);
          }}
        />
        {/* Valid until */}
        {sub.status === "deleted" && (
          <AccountsField
            label="Valid Until"
            value={sub.cancellation_effective_date}
          />
        )}
        {/* Payment method */}
        {sub.status !== "deleted" && sub.payment_method && (
          <AccountsField
            label="Payment Method"
            value={sub.payment_method}
            actionLabel="Update"
            onActionClick={() => {
              openURL(sub.update_url);
            }}
          />
        )}
        {/* Coupon */}
        {sub.coupon && sub.status !== "deleted" && (
          <AccountsField
            label="Coupon"
            value={`${sub.coupon.coupon} (${sub.coupon.discount}% off)`}
          />
        )}
        {/* Resubscribe */}
        {(sub.status === "deleted" || forceShowResubscribeOption) && (
          <Button
            primary
            label="Re-subscribe"
            onClick={() => {
              Paddle.Checkout.open({
                product: 589200,
                email: user.email,
                disableLogout: true
              });
            }}
            style={{ width: "14em" }}
          />
        )}
        {/* Convert to Lifetime */}
        {(sub.status === "trialing" || forceShowConvertToLifetimeOption) && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Button
              primary
              label="Convert to Lifetime"
              onClick={() => {
                Paddle.Checkout.open({
                  product: 522584,
                  email: user.email,
                  disableLogout: true,
                  allowQuantity: false,
                  passthrough: JSON.stringify({
                    action: {
                      type: "cancel_sub",
                      subscription_id: sub.subscription_id
                    }
                  })
                });
              }}
            />
            <span style={{ marginTop: "0.3em", color: "gray" }}>
              Pay one time instead of yearly
            </span>
          </div>
        )}
      </AccountsGrid>
    </div>
  );
};

const lifetimePurchaseDataToRow = (lifetime: LifetimePurchaseData) => (
  <Fragment>
    <H2>{`Your License`}</H2>
    <AccountsGrid columns={3}>
      <AccountsField label="Your Plan" value="Boxy Suite Lifetime" />
      <AccountsField
        label="Aumount Paid"
        value={`${currencySymbol(lifetime.currency)}${lifetime.sale_gross}`}
        actionLabel="Download Receipt"
        onActionClick={() => openURL(lifetime.receipt_url)}
      />
    </AccountsGrid>
  </Fragment>
);

const teamMembershipToRow = (
  teamMembership: BasicTeamInfo,
  teamOwnership: BasicTeamInfo | null
) => {
  let message;

  if (teamOwnership && teamOwnership.id === teamMembership.id) {
    // user is both member and owner of the team
    message = (
      <>
        <P1>
          You are admin and member of{" "}
          {teamMembership.name ? `${teamMembership.name}` : "a team"}
          {!teamMembership.effective
            ? " that is not currently active."
            : "."}{" "}
          You can manage your team from the{" "}
          <Link to={"/team"}>Team section</Link>.
        </P1>
        <P1>
          Team licensing options are explained{" "}
          <a href="https://boxysuite.com/teams" target="_blank">
            here
          </a>
          .
        </P1>
      </>
    );
  } else {
    message = (
      <>
        <P1>
          You are part of{" "}
          {teamMembership.name ? `${teamMembership.name}` : "a team"}
          {!teamMembership.effective
            ? " that is not currently active."
            : "."}{" "}
          For more information, contact the team owner{" "}
          <a href={`mailto:${teamMembership.owner.email}`}>
            {teamMembership.owner.name}
          </a>
          .
        </P1>
        <P1>
          Team licensing options are explained{" "}
          <a href="https://boxysuite.com/teams" target="_blank">
            here
          </a>
          .
        </P1>
      </>
    );
  }

  return <SimpleRow title="Your Team">{message}</SimpleRow>;
};

const SimpleRow = ({
  title,
  children
}: {
  title?: string;
  children: ReactNode;
}) => (
  <Fragment>
    <H2>{title}</H2>
    {children}
  </Fragment>
);

const Account: React.FC<{}> = props => {
  const authContext = useContext(AuthContext);
  useRefreshUserOnce();

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [
    subscriptionBeingCancelled,
    setSubscriptionBeingCancelled
  ] = useState<Subscription | null>(null);

  const user = authContext.user;

  const handleChangePassword = (oldPassword: string, newPassword: string) => {
    setChangePasswordLoading(true);

    api
      .changePassword(oldPassword, newPassword)
      .then(() => {
        setShowChangePasswordModal(false);
        setChangePasswordLoading(false);
      })
      .catch(error => {
        handleNetworkError(error);
        setChangePasswordLoading(false);
      });
  };

  useEffect(() => {
    Paddle.Setup({
      vendor: 28827,
      eventCallback: function(eventData: any) {
        const { event: eventName } = eventData;

        if (eventName === "Checkout.Complete") {
          // payment completed, wait a second and reload the page to show the new sub to the user
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    });
  }, []);

  if (!user) {
    return null;
  }

  const { name, email } = user;
  const password = "∙∙∙∙∙∙∙";
  const userHasNoLicense =
    !user.has_honorary_lifetime &&
    !user.is_press &&
    !user.used_appsumo_code &&
    !user.lifetime_purchase_data &&
    (!user.subscriptions || user.subscriptions.length === 0);

  return (
    <AdminPage>
      <DocumentTitle title="Account - Boxy Suite" />

      {subscriptionBeingCancelled && (
        <ModalWrapper big show>
          <CancelFlow
            redeemedOffers={user.redeemed_offers}
            subscription={subscriptionBeingCancelled}
            onCancel={() => {
              setSubscriptionBeingCancelled(null);
              authContext.refreshUser();
            }}
          />
        </ModalWrapper>
      )}

      <ChangePasswordModal
        show={showChangePasswordModal}
        loading={changePasswordLoading}
        onCancel={() => setShowChangePasswordModal(false)}
        onConfirm={handleChangePassword}
      />

      <H2>My Account</H2>
      <AccountsGrid columns={3}>
        <AccountsField label="Name" value={name} />
        <AccountsField label="Email" value={email} />
        <AccountsField
          label="Password"
          value={password}
          actionLabel="Change"
          onActionClick={() => setShowChangePasswordModal(true)}
        />
      </AccountsGrid>
      <div style={{ display: "grid" }}>
        {userHasNoLicense && (
          <SimpleRow>
            <P1>
              You have no active subscription. You can purchase one on our{" "}
              <a href="https://www.boxysuite.com/">website</a>. For team pricing
              options check{" "}
              <a href="https://www.boxysuite.com/teams">this page</a>.
            </P1>
          </SimpleRow>
        )}
        {user.has_honorary_lifetime && (
          <SimpleRow title="Your Plan">
            <P1>
              You have a free <strong>lifetime</strong> subscription 🙏 Enjoy!
              ✨
            </P1>
          </SimpleRow>
        )}
        {user.is_press && (
          <SimpleRow title="Press Account">
            <P1>
              This is a special press account. Feel free to share it with your
              colleagues in order to evaluate Boxy Suite.
            </P1>

            <P1>
              You can find our press kit{" "}
              <a
                href="https://www.boxysuite.com/press"
                rel="noopener noreferrer"
                target="_blank"
              >
                here
              </a>
              .
            </P1>
          </SimpleRow>
        )}
        {user.used_appsumo_code && (
          <>
            <H2>Your License</H2>

            <AccountsGrid columns={3}>
              <AccountsField label="Type" value="Lifetime License" />
              <AccountsField
                label="AppSumo Code"
                value={user.used_appsumo_code}
              />
            </AccountsGrid>
          </>
        )}
        {user.lifetime_purchase_data &&
          lifetimePurchaseDataToRow(user.lifetime_purchase_data)}
        {user.team_membership &&
          teamMembershipToRow(user.team_membership, user.team_ownership)}
        {user.subscriptions.map(sub =>
          subscriptionToRow(user, sub, setSubscriptionBeingCancelled)
        )}
      </div>
    </AdminPage>
  );
};

export default Account;
