import React, { useState, useLayoutEffect, useEffect } from "react";
import ModalWrapper from "../ModalWrapper";
import { H3, P1 } from "../styled";
import Button from "../Button";
import { Input, Loader, Dimmer } from "semantic-ui-react";

interface Props {
  show: boolean;
  loading?: boolean;

  title: string;

  confirmButtonLabel?: string;
  cancelButtonLabel?: string;

  inputPlaceholderText?: string;
  inputInitialValue?: string;

  onCancel?: () => void;
  onConfirm?: (inputText: string) => void;
}

const SingleInputModal: React.FC<Props> = props => {
  const {
    show,
    loading,
    title,
    confirmButtonLabel,
    cancelButtonLabel,
    inputPlaceholderText,
    inputInitialValue,
    onConfirm,
    onCancel
  } = props;

  const [text, setText] = useState(inputInitialValue || "");

  let inputRef: any = null;

  useEffect(
    () => {
      inputRef && inputRef.focus();
    },
    [show]
  );

  return (
    <ModalWrapper show={show} loading={loading}>
      <H3>{title}</H3>

      <Input
        style={{ width: "100%", marginBottom: "1.5em" }}
        type="text"
        value={text}
        onChange={e => setText(e.target.value)}
        placeholder={inputPlaceholderText}
        onKeyPress={(e: any) => {
          if (e.key === "Enter") {
            onConfirm && onConfirm(text);
          }
        }}
        ref={ref => (inputRef = ref)}
      />

      <br />

      <Button
        primary
        right
        label={confirmButtonLabel || "Save"}
        onClick={() => onConfirm && onConfirm(text)}
      />

      <Button right label={cancelButtonLabel || "Cancel"} onClick={onCancel} />
    </ModalWrapper>
  );
};

export default SingleInputModal;
