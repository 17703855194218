import React from "react";
import ModalWrapper from "./ModalWrapper";
import { H3, P1 } from "./styled";
import Button from "../components/Button";

export default props => {
  const {
    title,
    description,
    show,
    actionLabel,
    cancelLabel,
    onActionClick,
    onCancelClick
  } = props;

  return (
    <ModalWrapper show={show}>
      <H3>{title}</H3>
      <P1>{description}</P1>
      <br />
      <Button primary right label={actionLabel} onClick={onActionClick} />
      <Button right label={cancelLabel} onClick={onCancelClick} />
    </ModalWrapper>
  );
};
