import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const InlineButtonWrapper = styled.button`
  display: inline;
  padding: 0;
  cursor: pointer;
  font-weight: inherit;
  font-size: inherit;
  text-decoration: underline;
  color: inherit;
  border: none;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  overflow: hidden;
`;

const InlineButton = props => {
  const { label, onClick } = props;

  return (
    <InlineButtonWrapper onClick={onClick} {...props}>
      {label}
    </InlineButtonWrapper>
  );
};

InlineButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default InlineButton;
