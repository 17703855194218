import React from "react";
import { H2, P1 } from "../../components/styled";
import Button from "../../components/Button";

export default props => {
  const { onDismiss } = props;

  return (
    <>
      <H2>✅ &nbsp;50% OFF discount applied</H2>
      <P1>
        Enjoy Boxy Suite for half the price. Thanks so much for supporting us.
      </P1>

      <br />

      <Button primary label="OK" onClick={onDismiss} />
    </>
  );
};
